<template>
  <div class="mini_game_bet">
    <div class="mini_game">

        <div class="m1">
          <div class="mini_fram">
            <leisure-game-ifram-view>
<!--              <iframe id="bet365-gamefram" scrolling="no" frameborder="0"
                      src="https://vet3.net/video?q=high&player=1&autostart=true&title=Hillside Park"></iframe>-->

              <iframe id="bet365-gamefram" scrolling="no" frameborder="0"
                      src="https://b2.nutv365.com/ios/?vn=4"></iframe>
            </leisure-game-ifram-view>
          </div>

        </div>
        <div class="m2">

          <div class="game_tab">
            <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
            <leisure-timer-comp
                    ref="ltimer"
                    :leisure-game-info="leisureGameInfo"
                    @timerStop="timerStop" @refreshTimer="refreshTimer">
              <span slot="title" class="text-orange"><i class="fa fa-star"></i>힐사이드</span>
            </leisure-timer-comp>

            <!--선택부분-->
            <div class="mini_bet_box">
              <div class="mg">
                <span class="t">우승번호</span>
                <div class="mg_btn b3"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay === 1}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,1, dograceGame.bedang1, dograceConfig,'1번')">
                  <div class="r">
                    <span class="n text_color01">1번</span>
                    <span class="b text-orange">{{dograceGame.bedang1}}</span>
                  </div>
                </div>
                <div class="mg_btn b3"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay ===2}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,2, dograceGame.bedang2, dograceConfig,'2번')">
                  <div class="r">
                    <span class="n text_color01">2번</span>
                    <span class="b text-orange">{{dograceGame.bedang2}}</span>
                  </div>
                </div>
                <div class="mg_btn b3"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay === 3}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,3, dograceGame.bedang3, dograceConfig,'3번')">
                  <div class="r">
                    <span class="n text_color01">3번</span>
                    <span class="b text-orange">{{dograceGame.bedang3}}</span>
                  </div>
                </div>
                <div class="mg_btn b3 mt5"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay ===4}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,4, dograceGame.bedang4, dograceConfig,'4번')">
                  <div class="r">
                    <span class="n text_color01">4번</span>
                    <span class="b text-orange">{{dograceGame.bedang4}}</span>
                  </div>
                </div>
                <div class="mg_btn b3 mt5"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay === 5}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,5, dograceGame.bedang5, dograceConfig,'5번')">
                  <div class="r">
                    <span class="n text_color01">5번</span>
                    <span class="b text-orange">{{dograceGame.bedang5}}</span>
                  </div>
                </div>
                <div class="mg_btn b3 mt5"
                     :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL && selectedInfo.selectedWay === 6}"
                     @click="selected(leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL,dograceGame.id,6, dograceGame.bedang6, dograceConfig,'6번')">
                  <div class="r">
                    <span class="n text_color01">6번</span>
                    <span class="b text-orange">{{dograceGame.bedang6}}</span>
                  </div>
                </div>
              </div>


            </div>

            <!--파워볼 베팅카트-->
            <leisure-bet-cart-comp
                    ref="betCart" :kind="kind"
                    :selected-info="selectedInfo">
              힐사이드
            </leisure-bet-cart-comp>

          </div>
        </div>
        <div class="m3">
          <!--최근 배팅내역-->
          <leisure-lately-bet-list-comp
                  ref="latelyBetList"
                  :kind="kind"></leisure-lately-bet-list-comp>
        </div>
      </div>
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import sportsConst from "../../../common/sportsConst";
  import {getLeisureGameAttributeConfig, getOnBetLeisureGames} from "../../../network/leisureRequest";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import {leisureGameMixin} from "../../../common/mixin";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
  import LeisureGameLinks from "../LeisureGameLinks";
  import LeisureBet365DogLinks from "../LeisureBet365DogLinks";
  import LeisureGameIframView from "../LeisureGameIframView";
  import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

  export default {

    name: "Bet365DogRaceGoldenHill",
    mixins: [leisureGameMixin],
    components: {
      LeisureGameIframView,
      LeisureBet365DogLinks,
      LeisureGameLinks,
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK,
      }
    },
    methods: {
      initLeisureGameArttributeConfig() {
        getLeisureGameAttributeConfig().then(res => {
          if (res.data.success) {
            this.attrConfig = res.data.data
            //배당설정
            this.dograceConfig = this.attrConfig.find(attr => {
              return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_BET365_DOGRACE_HILLSIDEPARK_NORMAL)
            })
          }
        })
      }
    },
    created() {
      this.initKindConfig(this.kind)
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_BET365);
      this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
    },
  }
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .mini_game .m2{
    --margin-top: 180px;
  }
  .mini_game .mini_fram{
    min-height: 210px!important;
  }
}
</style>